<template>
  <div>
    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="30%">
      <span>该微信已注册,不能绑定此微信</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="cleardialogVisible">确定</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>
import {wxLoginApi, bindThirdParty, updateStudyDay,bindTeacherWx,teacherWxLoginApi} from "@/api";
export default{
  data(){
    return{
      dialogVisible:false
    }
  },
  methods:{
    cleardialogVisible(){
      this.dialogVisible=false;
      window.location.href = "http://exiaozhi.sdzjds.com"
    }
  },
  mounted() {
    let userInfo=JSON.parse(localStorage.getItem("userInfo")) ;
    let code=sessionStorage.getItem("code");
    let bound=sessionStorage.getItem("bound");
    let teacherBound=sessionStorage.getItem("teacherBound");
    let userWxlogin=sessionStorage.getItem("userWxlogin");
    let teacherWxlogin=sessionStorage.getItem("teacherWxlogin");
    if(code&&bound){
      let boundData={
        code:code,
        terminalType:'WEB'
      };
      //学生绑定微信
      bindThirdParty(boundData).then(res=>{
        if (res.code == 0){
          this.$message({
            message: "绑定成功",
            type: "success",
            duration:2000
          });
          let userInfo = JSON.stringify(res.data)
          localStorage.setItem("userInfo", userInfo);
          this.$cookie.set("userToken", res.data.loginToken);
          window.location.href = "http://exiaozhi.sdzjds.com"
        }else if(res.code=='10521'){
          this.dialogVisible=true
        }
      });
      //教师绑定微信
    }else if(code&&!bound&&teacherBound){
      console.log(teacherBound)
      let boundData={
        code:code,
        terminalType:'WEB'
      };
      bindTeacherWx(boundData).then(res=>{
        if (res.code == 0){
          this.$message({
            message: "绑定成功",
            type: "success",
            duration:2000
          });
          let userInfo = JSON.stringify(res.data)
          localStorage.setItem("userInfo", userInfo);
          this.$cookie.set("userToken", res.data.loginToken);
          window.location.href = "http://exiaozhi.sdzjds.com"
        }
      });
    //  教师微信登录
    }else if(code&&!bound&&!teacherBound&&teacherWxlogin&&!userWxlogin){
      let data={
        code: code,
        terminalType:'WEB'
      }
      teacherWxLoginApi(data).then(res=>{
        if(res.code == 0){
          this.$message({
            message: "登录成功",
            type: "success",
            duration:2000
          });
          let userInfo = JSON.stringify(res.data)
          localStorage.setItem("userInfo", userInfo);
          this.$cookie.set("userToken", res.data.loginToken);
          if(res.data.name){
            this.$cookie.set("userName", res.data.name);
            this.$store.commit("setUserName", res.data.name);
          }else{
            this.$cookie.set("userName", res.data.nickName);
            this.$store.commit("setUserName", res.data.nickName);
          }
          sessionStorage.setItem("closeCodeWindow", '1');
          sessionStorage.removeItem("code")
          window.location.href = "http://exiaozhi.sdzjds.com"
        }else{
          this.$message.error('需绑定微信后,才可微信登录');
          window.location.href ='http://exiaozhi.sdzjds.com/#/reg'
        }
      })
      //  学生微信登录
    }else if(code&&!bound&&!teacherBound&&userWxlogin&&!teacherWxlogin){
      console.log(teacherBound)
      let data={
        code: code,
        terminalType:'WEB'
      }
      wxLoginApi(data).then(res=>{
        if(res.code == 0){
          this.$message({
            message: "登录成功",
            type: "success",
            duration:2000
          });
          let userInfo = JSON.stringify(res.data)
          localStorage.setItem("userInfo", userInfo);
          this.$cookie.set("userToken", res.data.loginToken);
          if(res.data.name){
            this.$cookie.set("userName", res.data.name);
            this.$store.commit("setUserName", res.data.name);
          }else{
            this.$cookie.set("userName", res.data.nickName);
            this.$store.commit("setUserName", res.data.nickName);
          }
          sessionStorage.setItem("closeCodeWindow", '1');
          sessionStorage.removeItem("code")
          updateStudyDay().then(res=>{

          })
          window.location.href = "http://exiaozhi.sdzjds.com"
        }
      })
    }
  },
}
</script>
